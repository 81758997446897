import React, { useState, useEffect } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CameraCard from './CameraCard';
import './CameraViewer.css';

const CameraViewer = ({ email }) => {
  const [cameras, setCameras] = useState([]);
  const [simData, setSimData] = useState({});
  const [editingCamera, setEditingCamera] = useState(null);
  const [nickname, setNickname] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); 
  const [isLoading, setIsLoading] = useState(false); 
  const navigate = useNavigate();
  const [chartVisibility, setChartVisibility] = useState({});

  const toggleChartVisibility = (camUid) => {
    setChartVisibility(prevState => ({
      ...prevState,
      [camUid]: !prevState[camUid]
    }));
  };

  useEffect(() => {
    const initialVisibility = {};
    cameras.forEach(camera => {
      initialVisibility[camera.CAM_UID] = false;
    });
    setChartVisibility(initialVisibility);
  }, [cameras]);

  const fetchSimData = async (simIccid, camUid) => {
    try {
      const response = await fetch(`/fetch-sim-data/${simIccid}`);
      if (!response.ok) {
        throw new Error('SIM not activated');
      }
      const data = await response.json();
      setSimData(prevSimData => ({ ...prevSimData, [camUid]: data.content }));
    } catch (error) {
      setSimData(prevSimData => ({ ...prevSimData, [camUid]: { error: error.message } }));
    }
  };



  const fetchDataUsage = async (iccid) => {
    try {
      const response = await fetch(`/api/data-usage/${iccid}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      // Assuming the backend returns an object with 'totalUsage' and 'dailyUsage' properties
      return {
        totalUsage: data.totalHours, // This holds the total data usage number
        dailyUsage: data.dailyHours, // This should be an object with dates as keys and usage as values
      };
    } catch (error) {
      console.error('Error fetching data usage:', error);
      return { totalUsage: 0, dailyUsage: {} }; // Return an object with the same structure in case of an error
    }
  };







  const fetchCameras = async () => {
    setIsLoading(true);
    console.log('Fetching cameras for email:', email);
    try {
      const response = await fetch(`/cameras?email=${email}`);
      console.log('response:', response);
      if (!response.ok) {
        const data = await response.json();
        console.error('Error fetching cameras:', data);
        console.error('Error fetching cameras:', data.message);
        setErrorMessage(data.message);
        setIsLoading(false);
        return;
      }
  
      const cameraData = await response.json();
      if (cameraData.length === 0) {
        setErrorMessage('No cameras found for this email');
        setIsLoading(false);
        return;
      }
  
      // Fetch additional SIM data and data usage
      const updatedCameraData = await Promise.all(cameraData.map(async (camera) => {
        await fetchSimData(camera.SIM_ICCID, camera.CAM_UID);
  
        if (camera.SIM_ICCID !== "WiFi") {
          const usage = await fetchDataUsage(camera.SIM_ICCID);
          return { ...camera, dataUsage: usage };
        }
  
        return camera;
      }));
  
      setCameras(updatedCameraData);
    } catch (error) {
      console.error('Error fetching camera data:', error);
      
      setErrorMessage('An error occurred while fetching camera data.');
    }
  
    setIsLoading(false);
  };
  

  useEffect(() => {
    fetchCameras();
  }, [email]);

  const handleEdit = (camera) => {
    setEditingCamera(camera.CAM_UID);
    setNickname(camera.Nickname || '');
  };

  const handleSave = async (camUid) => {
    const response = await fetch('/update-nickname', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ camUid, nickname }),
    });

    const data = await response.json();
    if (data.success) {
      setEditingCamera(null);
      setCameras(prevCameras =>
        prevCameras.map(camera => 
          camera.CAM_UID === camUid ? { ...camera, Nickname: nickname } : camera
        )
      );
    } else {
      setErrorMessage('Failed to update nickname');
    }
  };

  const activateSim = async (camera) => {
    console.log('Activating SIM for camera UID:', camera.CAM_UID);
    navigate('/activate-sim', { state: { selectedIccid: camera.SIM_ICCID, email: email, cameraUid: camera.CAM_UID } });
  };

  if (isLoading) {
    return (
      <Container className="mt-5 text-center">
        <Spinner animation="border" role="status" />
        <div>Loading...</div>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      <h2 className="text-center">Registered Cameras</h2>
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      {cameras.map((camera) => (
        <CameraCard
          key={camera.CAM_UID}
          camera={camera}
          simData={simData}
          chartVisibility={chartVisibility}
          toggleChartVisibility={toggleChartVisibility}
          editingCamera={editingCamera}
          setEditingCamera={setEditingCamera}
          nickname={nickname}
          setNickname={setNickname}
          activateSim={activateSim}
          handleSave={handleSave}
        />
      ))}
    </Container>
  );
};

export default CameraViewer;
