// This page is to give customers the option to select the type of code they have for their digital SIM. The page includes a form with two radio buttons for the two code types. 
//This page will entually be discontuined once all customers use the 4 digit activation code.

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SelectCodeType.css';  

function SelectCodeType() {
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const codeType = formData.get('codeType');

    if (codeType === 'ICCID') {
      navigate('/ICCID');
    } else if (codeType === 'ActivationCode') {
      navigate('/activate');
    }
  };

  return (
    <div className="select-code-container">
      <h2 className="select-code-title">Select the code type:</h2>
      <p className="instruction-text">
        The code for your digital SIM will be located on your camera or on the SIM if you're upgrading an older camera to a Farmstream plan.
      </p>
      <form onSubmit={handleSubmit} className="select-code-form">
        <div className="option-container">
          <label className="option-label">
            <input type="radio" name="codeType" value="ActivationCode" className="select-code-input" />
            <span className="radio-custom"></span>
            4-digit Activation Code
          </label>
          <label className="option-label">
            <input type="radio" name="codeType" value="ICCID" className="select-code-input" />
            <span className="radio-custom"></span>
            20-digit ICCID number
          </label>

        </div>
        <button type="submit" className="select-code-submit">Continue</button>
      </form>
    </div>
  );
}

export default SelectCodeType;
