// Importing necessary React, Bootstrap components, and other dependencies
import React from 'react';
import { Card, ListGroup, Button, Form } from 'react-bootstrap';
import DataUsageChart from './DataUsageChart'; // Custom component to display data usage
import { useNavigate } from 'react-router-dom'; // Hook for navigation
import './CameraCard.css'; // Styling for the component

// The CameraCard component displays details about a camera and allows interaction like editing and navigating to invoices or plan changes
const CameraCard = ({
  camera, // Camera details object
  simData, // SIM data for the camera
  chartVisibility, // Controls visibility of the data usage chart
  toggleChartVisibility, // Function to toggle the chart visibility
  editingCamera, // Indicates if a camera is currently being edited
  setEditingCamera, // Function to set a camera to be edited
  nickname, // The editable nickname for the camera
  setNickname, // Function to update the nickname
  handleSave // Function to handle saving changes to the camera details
}) => {
 const navigate = useNavigate(); // Hook for navigation

  // Handles the initiation of editing camera details
  const handleEditStart = () => {
    setEditingCamera(camera.CAM_UID); // Marks the camera as being edited
    setNickname(camera.Nickname || ''); // Sets the nickname to current or empty string
  };

  // Navigates to the invoices page for the camera
  const goToInvoices = () => {
    if (!camera.STRIPE_CUSTOMER_ID) {
      console.error("Stripe Customer ID is undefined.");
      return;
    }
    navigate(`/invoices/${camera.STRIPE_CUSTOMER_ID}`);
  };

  
  // Navigates to the page for changing the camera's plan
  const handleChangePlan = () => {
    if (!camera.STRIPE_CUSTOMER_ID) {
      console.error("Stripe Customer ID is undefined.");
      return;
    }
    navigate(`/change-plan/${camera.STRIPE_CUSTOMER_ID}`);
  };

  return (
    <Card className="mb-3">
      <Card.Header>
        {editingCamera === camera.CAM_UID ? ( // Check if the camera is being edited
          <div className="editing-controls">
            <Form.Control
              type="text"
              value={nickname} // Display the nickname in an editable text field
              onChange={(e) => setNickname(e.target.value)} // Update nickname on change
            />
            <Button variant="success" onClick={() => handleSave(camera.CAM_UID)} className="ms-2">
              Save
            </Button>
          </div>
        ) : (
          <div>
            {camera.Nickname || `Camera ${camera.CAM_UID}`}
            <i 
              className="fas fa-edit edit-icon" 
              onClick={handleEditStart} // Initiate editing on click
              style={{ marginLeft: '10px', cursor: 'pointer' }}
            ></i>
          </div>
        )}
      </Card.Header>
      <ListGroup variant="flush">
        <ListGroup.Item>Camera UID: {camera.CAM_UID}</ListGroup.Item>
        <ListGroup.Item>SIM Number: {camera.SIM_ICCID}</ListGroup.Item>
        {simData[camera.CAM_UID]?.isActive && ( // Conditionally render sections based on SIM status
          <>
            <ListGroup.Item>
              Current Monthly Usage: {camera.dataUsage && camera.dataUsage.totalUsage !== undefined ? `${camera.dataUsage.totalUsage} hours` : 'Loading...'}
              <i 
                className={`fas ${chartVisibility[camera.CAM_UID] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                onClick={() => toggleChartVisibility(camera.CAM_UID)} // Toggle chart visibility on click
                style={{ cursor: 'pointer', marginLeft: '10px' }}
              ></i>
            </ListGroup.Item>
            {chartVisibility[camera.CAM_UID] && (
              <ListGroup.Item>
                <DataUsageChart dailyHours={camera.dataUsage && camera.dataUsage.dailyUsage ? camera.dataUsage.dailyUsage : {}} />
              </ListGroup.Item>
            )}
            <ListGroup.Item>
              Percentage of plan used: {
                ((camera.dataUsage.totalUsage / (camera.DataGB * 1.5)) * 100).toFixed(1) // Calculate and display percentage of plan used (currently shoing hours rather than GB hece times by 1.5)
              }%
            </ListGroup.Item>
          </>
        )}
        <ListGroup.Item>
          <Button variant="secondary" onClick={goToInvoices}>Invoices</Button>
          <Button variant="info" onClick={handleChangePlan} className="ms-2 camera-card-btn">Change Plan</Button>
          
  
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};

export default CameraCard;

