// InvoicesPage.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './InvoicesPage.css'; // Make sure this path is correct
import { Spinner } from 'react-bootstrap';



const InvoicesPage = () => {
    const { customerId } = useParams();
    const [invoices, setInvoices] = useState([]);
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadSuccessMessage, setDownloadSuccessMessage] = useState('');
    
    useEffect(() => {
        const fetchInvoices = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`/fetch-invoices/${customerId}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                console.log(data);
                setInvoices(data.data); // assuming the invoices are in the `data` field
            } catch (error) {
                console.error('Error fetching invoices:', error);
                setError('Failed to fetch invoices.');
            }
            setIsLoading(false);
        };
    
        fetchInvoices();
    }, [customerId]);


    const formatDate = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000); // Convert to milliseconds
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`; // Format: dd/mm/yyyy
      };
      

    const handleCheckboxChange = (invoiceId) => {
        if (selectedInvoices.includes(invoiceId)) {
            setSelectedInvoices(selectedInvoices.filter(id => id !== invoiceId));
        } else {
            setSelectedInvoices([...selectedInvoices, invoiceId]);
        }
    };

    const downloadSelectedInvoices = async () => {
        setIsDownloading(true); // Start loading
        setDownloadSuccessMessage(''); // Reset success message
        setError(''); // Reset error message
    
        const selectedInvoiceData = selectedInvoices.map(invoiceId => {
            const invoice = invoices.find(invoice => invoice.id === invoiceId);
            return {
                url: invoice.invoice_pdf,
                name: `Invoice_${invoice.number}.pdf`
            };
        });
    
        try {
            console.log("Sending invoice data to backend:", selectedInvoiceData);
    
            const response = await fetch('/download-invoices', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ invoices: selectedInvoiceData }),
            });
    
            if (response.ok) {
                const blob = await response.blob();
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'invoices.zip');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
    
                setDownloadSuccessMessage('Invoices downloaded successfully!');
            } else {
                throw new Error('Failed to download invoices.');
            }
        } catch (error) {
            console.error('Error downloading zipped invoices:', error);
            setError('Error downloading invoices. Please try again.');
        } finally {
            setIsDownloading(false); // End loading
        }
    };
    
    


    if (isLoading) {
        return <div className="loading">Loading invoices...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (invoices.length === 0) {
        return <div>No invoices found.</div>;
    }
    return (
        <div className="invoices-container">
            <div className="invoices-header">
                <h2 className="invoices-title">Invoices for selected camera</h2>
            </div>
            <table className="invoices-table">
                <thead>
                    <tr>
                        <th></th> {/* For checkbox */}
                        <th>Invoice Number</th>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Amount Due</th>
                        {/* Add other headings as needed */}
                    </tr>
                </thead>
                <tbody>
                    {invoices.map(invoice => (
                        <tr key={invoice.id} className="invoice">
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedInvoices.includes(invoice.id)}
                                    onChange={() => handleCheckboxChange(invoice.id)}
                                />
                            </td>
                            <td>{invoice.number}</td>
                            <td>{formatDate(invoice.created)}</td>
                            <td>{invoice.billing_reason}</td>
                            <td>{(invoice.amount_due / 100).toFixed(2)} {invoice.currency.toUpperCase()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
    
            {/* Messages just above the download button */}
            {/*{isDownloading && <div className="downloading">Downloading invoices...</div>}
            {downloadSuccessMessage && <div className="success-message">{downloadSuccessMessage}</div>}
            {error && <div className="error-message">Error: {error}</div>} */}
            {selectedInvoices.length > 0 && (
                <button onClick={downloadSelectedInvoices} className="download-button" disabled={isDownloading}>
                    {isDownloading ? (
                        <>
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            <span className="ms-2">Downloading...</span>
                        </>
                    ) : (
                        "Download Selected Invoices"
                    )}
                </button>
            )}
            
            {downloadSuccessMessage && <div className="success-message">{downloadSuccessMessage}</div>}
            {error && <div className="error-message">Error: {error}</div>}
        </div>
    );
    
    
};

export default InvoicesPage;
