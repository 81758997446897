import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './StripePricingPage.css'; // Import your custom CSS file

const StripePricingPage = () => {
  const location = useLocation();
  const selectedIccid = location.state?.selectedIccid;
  const customerEmail = location.state?.email;
  const cameraUid = location.state?.cameraUid;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [selectedIccid, customerEmail, cameraUid]);

  return (
    <div className="pricing-page-container">
      <h2 className="camera-activation-info"> Camera: {cameraUid}</h2>
      <h2 className="camera-activation-info"> SIM: {selectedIccid}</h2>

      <div className="plan-selection-text">
        <h2 className="section-title">Choose Your Plan</h2>
        <p>Select the plan which best suits your needs.</p>
        <p>We offer savings if you purchase your streaming data annually and plans can be upgraded/downgraded.</p>
      </div>

      <stripe-pricing-table
        pricing-table-id="prctbl_1NVdXSLUMGsj0i84ytBJFXKF"
        publishable-key="pk_live_51NSIcfLUMGsj0i84eNlhV0h0NzkZ46yBvoGhksQ9tFfX3NOIsIRMuiOZA3mpS5ifw2ivN6URgo0GPwOp6Xndrzyv00ojRywbNv"
        client-reference-id={selectedIccid}
        customer-email={customerEmail}>
      </stripe-pricing-table>
    </div>
  );
};

export default StripePricingPage;


