import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Alert, Spinner } from 'react-bootstrap';
import './Login.css';

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const navigate = useNavigate();
  const location = useLocation();

  // Toggle for enabling test mode
  const isTestMode = false; // Set this to 'false' in production

  useEffect(() => {
    if (!isTestMode) {
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get('token');
      if (token) {
        verifyToken(token);
      }
    }
  }, [location, isTestMode]);

  const handleLoginRequest = async (event) => {
    event.preventDefault();
    setMessage('');
    setIsLoading(true); // Start loading
  
    // If in test mode, directly login
    if (isTestMode) {
      onLogin(email);
      navigate('/cameras');
      setIsLoading(false); // Stop loading
    } else {
      // Normal login process
      try {
        const response = await fetch('/request-login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });
  
        const data = await response.json();
        if (data.success) {
          setMessage('Check your email for a login link.');
        } else {
          setMessage(data.message || 'Failed to send magic link. Please try again or contact support.');
        }
      } catch (error) {
        console.error('Login request failed', error);
        setMessage('An error occurred. Please try again later.');
      } finally {
        setIsLoading(false); // Stop loading regardless of success or error
      }
    }
  };
  
  // Function to verify the token sent via email
  const verifyToken = async (token) => {
    try {
      console.log('Verifying token:', token); // Log the token being verified
      const response = await fetch('/verify-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
  
      const data = await response.json();
      console.log('Token verification response:', data); // Log the response from the backend
      if (data.success) {
        // Token is valid, proceed with user login
        onLogin(data.email); // Handle the login process (e.g., setting user context or local storage)
        navigate('/cameras'); // Navigate to a protected route or dashboard upon successful login
      } else {
        // Token verification failed
        setMessage('Failed to verify login link. It might be expired or invalid.');
      }
    } catch (error) {
      console.error('Token verification failed', error);
      setMessage('An error occurred during login. Please try again later.');
    }
  };
  

  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h2 className="text-center">Login</h2>
          {message && <Alert variant="info">{message}</Alert>}
          <Form onSubmit={handleLoginRequest}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Button className="custom-login-btn mt-3" type="submit" disabled={isLoading}>
              {isLoading ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : (
                isTestMode ? 'Login (Test Mode)' : 'Send Login Link'
              )}
            </Button>

          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;





