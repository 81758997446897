import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import './ActivationICCID.css';

// Updated component name to reflect its new purpose
// Updates


const ActivationCodeInputs = ({ onCodeComplete }) => {
  const [code, setCode] = useState('');

  const handleChange = (event) => {
    // Only allow numbers, limit to 20 characters
    const processedValue = event.target.value.replace(/[^0-9]/g, '').slice(0, 20);
    setCode(processedValue);

    // Call onCodeComplete when 20 digits have been entered
    if (processedValue.length === 20) {
      onCodeComplete(processedValue);
    }
  };

  return (
    <div className="activation-code-input-container">
      <input
        type="text"
        maxLength="20"
        value={code}
        onChange={handleChange}
        className="full-width-input"
        placeholder="Enter 20-digit Number"
      />
      <p className="digit-count">{code.length}/20 digits entered</p>

    </div>
  );
};


    
const StripePricingPage = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [email, setEmail] = useState('');
  const [activationCode, setActivationCode] = useState(queryParams.SIM_Activation_Code || '');
  const [codeVerified, setCodeVerified] = useState(false); // Initially, code is not verified
  const [iccid, setIccid] = useState('');
  const [cameraUid, setCameraUid] = useState('');
  const [codeVerificationError, setCodeVerificationError] = useState(''); // New state for tracking code verification errors

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (activationCode && !codeVerified) {
      verifyCode(activationCode);
    }
  }, [activationCode]);
  
  const verifyCode = async (code) => {
    try {
      const response = await fetch('/verifyCode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      });
  
      if (!response.ok) {
        const data = await response.json(); // Parse the JSON only if the response is not ok.
        // The frontend now also checks for the status code of 405, indicating the SIM is already activated.
        if (response.status === 404) {
          throw new Error(data.message || 'SIM not found. Please check your activation code and try again.');
        } else if (response.status === 405) {
          throw new Error(data.message || 'This SIM is already activated. Please use a SIM that has not been activated yet.');
        } else {
          throw new Error('An error occurred during code verification. Please try again later.');
        }
      }
  
      const data = await response.json(); // Assume valid response returns JSON.
  
      if (data.valid && !data.activated) {
        // SIM found and not activated
        setCodeVerified(true);
        setIccid(data.iccid);
        setCameraUid(data.cameraUid);
        setCodeVerificationError('');
      } else if (data.valid && data.activated) {
        // SIM found but already activated
        setCodeVerified(false);
        setCodeVerificationError('This SIM is already activated. Please use a SIM that has not been activated yet.');
      } else {
        // Other cases treated as invalid SIM
        setCodeVerified(false);
        setCodeVerificationError(data.message || 'SIM not found. Please check your activation code and try again.');
      }
    } catch (error) {
      console.error('Error verifying code:', error);
      setCodeVerified(false);
      setCodeVerificationError(error.message);
    }
  };
  
  const handleEmailSubmit = (e) => {
    e.preventDefault();
    setEmail(e.target.email.value);
  };

  return (
    <div className="pricing-page-container">
      {!email ? (
        <div className="email-entry">
          <h4>Please enter the email address you wish to link to your new SIM:</h4>
          <form onSubmit={handleEmailSubmit} className="email-form">
            <input
              name="email"
              type="email"
              className="email-input"
              placeholder="Email address"
              required
            />
            <button type="submit" className="submit-button">Submit</button>
          </form>
        </div>
      ) : !codeVerified ? (
        <>
          <div className="code-form">
            <h2>Enter the 20 Digit ICCID Code</h2>
            <ActivationCodeInputs onCodeComplete={(code) => setActivationCode(code)} />
          </div>
          {codeVerificationError && <p className="error-message">{codeVerificationError}</p>}
        </>
      ) : (
        <>
          <h2>Activate your SIM</h2>
          <div className="camera-activation-info">
            <p>SIM: {iccid} (binding to {email})</p>
            {/* {cameraUid !== "N/A" && <p>Camera UID: {cameraUid}</p>} */}
            <p className="note">Please double check the SIM details match before proceeding</p>
          </div>
          <div className="plan-selection-text">
            <h2 className="section-title">Choose Your Plan</h2>
            <p>We offer savings if you purchase your streaming data annually, and plans can be upgraded or downgraded at any time.</p>
            <stripe-pricing-table
              pricing-table-id="prctbl_1OR0AoLUMGsj0i84a2Wpx31T"
              publishable-key="pk_live_51NSIcfLUMGsj0i84eNlhV0h0NzkZ46yBvoGhksQ9tFfX3NOIsIRMuiOZA3mpS5ifw2ivN6URgo0GPwOp6Xndrzyv00ojRywbNv"
              client-reference-id={iccid}
              customer-email={email}>
            </stripe-pricing-table>
          </div>
        </>
      )}
    </div>
  );
};

export default StripePricingPage;
