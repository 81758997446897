// ConfirmationModal.js
import React from 'react';
import './ConfirmationModal.css'; // Assume you create some basic CSS for the modal

function ConfirmationModal({ isOpen, onClose, onConfirm, message }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p className="modal-message">{message}</p>
        <button className="modal-button modal-confirm-button" onClick={onConfirm}>Confirm</button>
        <button className="modal-button modal-cancel-button" onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
}

export default ConfirmationModal;
