import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import './ActivationCodeFour.css';

const ActivationFourCodeInputs = ({ onCodeComplete }) => {
  const [digits, setDigits] = useState(Array(4).fill(''));
  const inputsRef = useRef([]);

  const handleChange = (value, index) => {
    const processedValue = value.replace(/[^a-zA-Z]/g, '').toUpperCase().slice(0, 1);
    const newDigits = [...digits];
    newDigits[index] = processedValue;
    setDigits(newDigits);

    if (processedValue && index < 3) {
      inputsRef.current[index + 1].focus();
    }

    if (newDigits.every(digit => digit !== '')) {
      onCodeComplete(newDigits.join(''));
    }
  };

  const handleKeyUp = (event, index) => {
    if (event.key === 'Backspace' && !digits[index] && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  return (
    <div className="activation-code-inputs">
      {digits.map((digit, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          value={digit}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyUp={(e) => handleKeyUp(e, index)}
          ref={(el) => inputsRef.current[index] = el}
          className="single-digit-input"
          pattern="[A-Z]"
        />
      ))}
    </div>
  );
};

const StripePricingPage = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [email, setEmail] = useState('');
  const [activationCode, setActivationCode] = useState(queryParams.SIM_Activation_Code || '');
  const [codeVerified, setCodeVerified] = useState(false); // Initially, code is not verified
  const [iccid, setIccid] = useState('');
  const [cameraUid, setCameraUid] = useState('');
  const [codeVerificationError, setCodeVerificationError] = useState(''); // New state for tracking code verification errors

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (activationCode && !codeVerified) {
      verifyCode(activationCode);
    }
  }, [activationCode]);
  
  const verifyCode = async (code) => {
    try {
      const response = await fetch('/verifyCode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      });
  
      if (!response.ok) {
        const data = await response.json(); // Parse the JSON only if the response is not ok.
        // Check if the status code is 404, indicating the entry was not found.
        if (response.status === 404) {
          throw new Error(data.message || 'SIM not found. Please check your activation code and try again.');
        } 
        if (response.status === 405) {
          throw new Error(data.message || 'SIM already activated.');
        }
        else {
          // Handle other types of HTTP errors generically.
          throw new Error('An error occurred during code verification. Please try again later.');
        }
      }
  
      const data = await response.json(); // Assume valid response returns JSON.
  
      if (data.valid) {
        setCodeVerified(true);
        setIccid(data.iccid);
        setCameraUid(data.cameraUid);
        setCodeVerificationError(''); // Reset error message on successful verification
      } else {
        setCodeVerified(false);
        // This is for cases where the server might return { valid: false } without throwing an error.
        // Adjust this message based on your backend's specific response structure and requirements.
        setCodeVerificationError('SIM not found. Please check your activation code and try again.');
      }
    } catch (error) {
      console.error('Error verifying code:', error);
      setCodeVerified(false);
      // Use the error message from the thrown Error.
      setCodeVerificationError(error.message);
    } 
  };
  
  
  

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    setEmail(e.target.email.value);
  };

  return (
    <div className="pricing-page-container">
      {!email ? (
        <div className="email-entry">
          <h4>Please enter the email address you wish to link to your new SIM:</h4>
          <form onSubmit={handleEmailSubmit} className="email-form">
            <input
              name="email"
              type="email"
              className="email-input"
              placeholder="Email address"
              required
            />
            <button type="submit" className="submit-button">Submit</button>
          </form>
        </div>
      ) : !codeVerified ? (
        <>
          <div className="code-form">
            <h2>Enter 4-Digit Activation Code</h2>
            <ActivationFourCodeInputs onCodeComplete={(code) => setActivationCode(code)} />
          </div>
          {codeVerificationError && <p className="error-message">{codeVerificationError}</p>}
        </>
      ) : (
        <>
          <h2>Activate your SIM</h2>
          <div className="camera-activation-info">
            <p>SIM: {iccid} (binding to {email})</p>
            {/* {cameraUid !== "N/A" && <p>Camera UID: {cameraUid}</p>} */}
            <p className="note">Please double check the SIM details match before proceeding</p>
          </div>
          <div className="plan-selection-text">
            <h2 className="section-title">Choose Your Plan</h2>
            <p>We offer savings if you purchase your streaming data annually, and plans can be upgraded or downgraded at any time.</p>
            <stripe-pricing-table
              pricing-table-id="prctbl_1OR0AoLUMGsj0i84a2Wpx31T"
              publishable-key="pk_live_51NSIcfLUMGsj0i84eNlhV0h0NzkZ46yBvoGhksQ9tFfX3NOIsIRMuiOZA3mpS5ifw2ivN6URgo0GPwOp6Xndrzyv00ojRywbNv"
              client-reference-id={iccid}
              customer-email={email}>
            </stripe-pricing-table>
          </div>
        </>
      )}
    </div>
  );
};

export default StripePricingPage;
