import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Login from './components/Login';
import CameraViewer from './components/CameraViewer';
import StripePricingPage from './components/StripePricingPage';
import InvoicesPage from './components/InvoicesPage';
import ChangePlanPage from './components/ChangePlanPage';
import ActivationCodeFour from './components/ActivationCodeFour';
import ActivationCodeTwenty from './components/ActivationCodeTwenty';
import SelectCodeType from './components/SelectCodeType';
import PausePlan from './components/PausePlan';

import './App.css';

function App() {
  const [loggedInUser, setLoggedInUser] = useState(null);

  const handleLogin = (email) => {
    setLoggedInUser(email);
  };

  const handleLogout = () => {
    setLoggedInUser(null);
  };


  useEffect(() => {
    // You might want to check for logged in user in local storage or perform some authentication check here
  }, []);

  return (
    <Router>
      <div className="App">
        <Header onLogout={handleLogout} />
        <Routes>
          <Route path="/login" element={!loggedInUser ? <Login onLogin={handleLogin} /> : <Navigate to="/cameras" />} />
          <Route path="/cameras" element={loggedInUser ? <CameraViewer email={loggedInUser} /> : <Navigate to="/login" />} />
          <Route path="/activate-sim" element={loggedInUser ? <StripePricingPage /> : <Navigate to="/login" />} />
          <Route path="/invoices/:customerId" element={loggedInUser ? <InvoicesPage /> : <Navigate to="/login" />} />
          <Route path="/change-plan/:customerId" element={loggedInUser ? <ChangePlanPage /> : <Navigate to="/login" />} />
          <Route path="/CodeType" element={<SelectCodeType />} />
          <Route path="/activate" element={<ActivationCodeFour />} />
          <Route path="/ICCID" element={<ActivationCodeTwenty />} />
          <Route path="/pause-plan/:customerId" element={loggedInUser ? <PausePlan /> : <Navigate to="/login" />} />
          

          {/* Default route */}
          <Route path="/" element={loggedInUser ? <Navigate to="/cameras" /> : <Navigate to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

// <Route path="/pause-plan" element={<PausePlan />} />