import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './PausePlan.css';

const PauseSimPage = () => {
    const { customerId } = useParams();
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [showConfirm, setShowConfirm] = useState(false); // Track whether to show confirmation box

    const monthOptions = Array.from({ length: 7 }, (_, i) => {
        const futureDate = new Date(new Date().setMonth(new Date().getMonth() + 6 + i));
        return {
            display: futureDate.toLocaleString('default', { month: 'long', year: 'numeric' }),
            value: futureDate.toISOString().slice(0, 7)
        };
    });

    const handleSelection = (month) => {
        setSelectedMonth(month);
        setShowConfirm(true);
    };

    const confirmPausePlan = async (isConfirmed) => {
        setShowConfirm(false);

        if (!isConfirmed) {
            setIsProcessing(false);
            return;
        }

        setIsProcessing(true);
        const reactivateDate = `${selectedMonth}-01`;

        try {
            const response = await fetch(`/api/pause-plan/${customerId}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ reactivateDate }),
            });

            const data = await response.json();

            if (response.ok) {
                const reactivateMonth = new Date(reactivateDate).toLocaleString('default', { month: 'long', year: 'numeric' });
                setSuccessMessage(`SIM paused successfully. Your plan will reactivate on the 1st of ${reactivateMonth}.`);
            } else {
                setErrorMessage(data.message || "Error pausing SIM.");
            }
        } catch (error) {
            setErrorMessage("Network or server error.");
        }

        setIsProcessing(false);
    };

    const getNextMonthStart = () => {
        const nextMonthStart = new Date();
        nextMonthStart.setMonth(nextMonthStart.getMonth() + 1, 1); // Set to the first day of the next month
        return nextMonthStart.toLocaleDateString(); // Format it to a readable string
    };

    return (
        <div className="pause-sim-container">
            <h2>Pause Your SIM Card</h2>
            <p>Your plan will be paused at the end of the current month and restart on the selected date. There will be a charge of £3 to cover the cost of keeping your SIM active.</p>
            <div class="action-label">Reactivate:</div>
            <div class="month-selection">
                {monthOptions.map((option, index) => (
                    <div key={index} className="month-option">
                        <button class="month-button" onClick={() => handleSelection(option.value)} disabled={isProcessing}>
                            {option.display}
                        </button>
                    </div>
                ))}
            </div>

            {showConfirm && (
                <div className="confirmation-dialog">
                    <p>Your SIM will be paused on the 1st of the next month ({getNextMonthStart()}). Are you sure you want to proceed?</p>
                    <div class="confirmation-buttons">
                        <button onClick={() => confirmPausePlan(true)}>Yes</button>
                        <button onClick={() => confirmPausePlan(false)}>No</button>
                    </div>
                </div>
            )}

            {successMessage && <div class="success-message">{successMessage}</div>}
            {errorMessage && <div class="error-message">{errorMessage}</div>}
        </div>
    );
};

export default PauseSimPage;
