import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// Register the Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const DataUsageChart = ({ dailyHours }) => {
  // Convert the data from hours to minutes and create a new array
  const dataInMinutes = Object.values(dailyHours).map((hours) => Math.round(hours * 60)); // Round to whole numbers

  const chartData = {
    labels: Object.keys(dailyHours),
    datasets: [
      {
        label: 'Minutes Streamed', // Update the label to display minutes
        data: dataInMinutes, // Use the data in minutes
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgba(75, 192, 192, 0.2)',
      },
    ],
  };

  const options = {
    scales: {
      x: {
        display: true,
        title: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 6,
          callback: function (value, index, values) {
            // Create a new Date object for each label
            const date = new Date(Object.keys(dailyHours)[index]);
            return date.toLocaleDateString(undefined, {
              month: 'short',
              day: 'numeric',
            });
          },
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Minutes', // Update the y-axis title to display minutes
        },
        ticks: {
          stepSize: 1, // Set the step size to 1 to display whole numbers
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default DataUsageChart;

