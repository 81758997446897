import React from 'react';
import { Link } from 'react-router-dom';
import logo from './4GCCTV.png';
import './Header.css';

const Header = ({ onLogout }) => {
  const logout = () => {
    onLogout();
  };

  return (
    <header>
      <div className="logo-container">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
      </div>
      <nav className="nav-container">
        <Link to="/cameras" className="nav-link">Account</Link>

        <a href="https://www.farmstream.co.uk" className="nav-link" onClick={logout}>Logout</a>

  
      </nav>
    </header>
  );
};

export default Header;
//        <Link to="/CodeType" className="nav-link">Activate</Link>