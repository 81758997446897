// page to allow customers to view their current subscription plan, change their plan, and view their next invoice date.
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ConfirmationModal from './ConfirmationModal';
import { useNavigate } from 'react-router-dom'; // Hook for navigation
import './ChangePlanPage.css';



const ChangePlanPage = () => {
    const { customerId } = useParams();
    const [currentPlan, setCurrentPlan] = useState(null);
    const [nextInvoiceDate, setNextInvoiceDate] = useState('');
    const [plans, setPlans] = useState([]);
    const [newPlanId, setNewPlanId] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
  const formatCurrency = (amount, currencyCode = 'GBP') => {
    return (amount / 100).toLocaleString('en-GB', {
      style: 'currency',
      currency: currencyCode,
    });
  };




  
  const getStreamingHours = (productName) => {
    const hoursByPlan = {
      'Light Streaming': '1.5 hours',
      'Standard Streaming': '4 hours',
      'Heavy Streaming': '8 hours',
    };
    return hoursByPlan[productName] || 'N/A';
  };

  const getStreamingHoursNumber = (productName) => {
    const hoursByPlan = {
      'Light Streaming': 1,
      'Standard Streaming': 3,
      'Heavy Streaming': 6,
    };
    return hoursByPlan[productName] || 'N/A';
  };


  const handlePlanChangeClick = () => {
    if (!newPlanId) {
      alert('Please select a plan.');
      return;
    }
    setIsModalOpen(true);
  };

  const handleConfirmChange = async () => {
    setIsModalOpen(false);
    setIsLoading(true);
  
    try {
      const response = await fetch('/change-plan', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId,
          newPlanId,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      setSuccessMessage('Plan updated and logged successfully!');
      await fetchUpdatedPlanDetails();
      await fetchNextInvoiceDate();
    } catch (error) {
      console.error('Error updating plan:', error);
      setError('Failed to update plan.');
    } finally {
      setIsLoading(false);
    }
  };
  
  const handlePausePlan = () => {
    if (!customerId) {
      console.error("Stripe Customer ID is undefined.");
      return;
    }
    navigate(`/pause-plan/${customerId}`);
  };

  const fetchUpdatedPlanDetails = async () => {
    try {
      const response = await fetch(`/current-subscription/${customerId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setCurrentPlan(data);
  
      // Now get the hours and log the plan update
      const hours = getStreamingHoursNumber(data.productName);
      console.log('Hours:', hours);
      const logResponse = await fetch('/update-data-usage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerID: customerId,
          hours,
        }),
      });
  
      if (!logResponse.ok) {
        throw new Error(`HTTP error on logging data! Status: ${logResponse.status}`);
      }
    } catch (error) {
      console.error('Error fetching updated subscription:', error);
      setError('Failed to fetch updated subscription details.');
    }
  };
  

  const fetchNextInvoiceDate = async () => {
    try {
      const response = await fetch(`/next-invoice-date/${customerId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setNextInvoiceDate(data.nextInvoiceDate); // Assuming the backend returns { nextInvoiceDate: 'YYYY-MM-DD' }
    } catch (error) {
      console.error('Error fetching next invoice date:', error);
      setError('Failed to fetch next invoice date.');
    }
  };


  useEffect(() => {
    fetchNextInvoiceDate(); // Fetch next invoice date
    const fetchCurrentSubscription = async () => {
      if (!customerId) {
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(`/current-subscription/${customerId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setCurrentPlan(data);
      } catch (error) {
        console.error('Error fetching current subscription:', error);
        setError('Failed to fetch current subscription.');
      } finally {
        setIsLoading(false);
      }
    };

    const fetchAvailablePlans = async () => {
      try {
        const response = await fetch('/available-plans');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setPlans(data);
      } catch (error) {
        console.error('Error fetching available plans:', error);
      }
    };

    fetchCurrentSubscription();
    fetchAvailablePlans();
    
  }, [customerId]);

  return (
    <div className="container">
      <h2 className="header">Current Subscription Plan</h2>

      {/* Conditional rendering for success message */}
      {/*successMessage && <div className="success-message">{successMessage}</div>}

      {/* Conditional rendering based on loading, error, and currentPlan state */}
      {isLoading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error}</div>
      ) : currentPlan ? (
        <div className="plan-container">
          <div className="plan-detail"><strong>Plan Name:</strong> {currentPlan.productName}</div>
          <div className="plan-detail"><strong>Amount:</strong> {formatCurrency(currentPlan.amount, currentPlan.currency)}</div>
          <div className="plan-detail"><strong>Billing Interval:</strong> {currentPlan.interval}</div>
          <div className="plan-detail"><strong>Hours of Streaming:</strong> {getStreamingHours(currentPlan.productName)}</div>
          <div className="invoice-date"><strong>Next Invoice Date:</strong> {nextInvoiceDate}</div>
        </div>
      ) : (
        <div>No current plan found.</div>
      )}

      <h2 className="header">Change Subscription Plan</h2>
      <form onSubmit={(e) => e.preventDefault()} className="form">
        <div>
          <label>Choose a plan:
            <select className="select" value={newPlanId} onChange={(e) => setNewPlanId(e.target.value)}>
              <option value="">Select a plan</option>
              {plans
                .filter(plan => plan.product.active && plan.active)
                .filter(plan => plan.currency === 'gbp')
                .sort((a, b) => a.unit_amount - b.unit_amount)
                .map(plan => (
                  <option key={plan.id} value={plan.id}>
                    {plan.product.name} - {formatCurrency(plan.unit_amount, plan.currency)} / 
                    {plan.recurring ? `${plan.recurring.interval_count} ${plan.recurring.interval}${plan.recurring.interval_count > 1 ? 's' : ''}` : 'One-time'}
                  </option>
                ))}
            </select>
          </label>
        </div>
        <button className="button" onClick={handlePlanChangeClick} disabled={isLoading}>Change Plan</button>
      </form>

      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmChange}
        message="Are you sure you want to change your plan? You will be billed the pro-rated amount on your next monthly invoice or immediately for annual plans."
      />

      {/* Footer section for support contact information and Pause Plan button */}
      <div className="support-info">
        <p>
          For help with your streaming plan or for plan pauses or cancellations, please get in contact at 
          <a href="mailto:support@farmstream.co.uk"> support@farmstream.co.uk</a>.
          {/* Button next to the support info */}
          <button onClick={handlePausePlan} className="pause-plan-help-button">Pause Plan</button>
        </p>
      </div>
    </div>
  );

};

export default ChangePlanPage;